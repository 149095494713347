import React from 'react';
import {Link} from 'gatsby'
import {Card, Grid} from "@material-ui/core";

import CardContent from "@material-ui/core/CardContent";
import Image from "./Image";

const { compose, withProps } = require("recompose");

export default function Empfehlungen({empfehlungen}) {
    return (
        <div>
            <div lg={12} className='dark-hero-content'>
                <div className='title-big'>Empfehlungen</div>
            </div>
            <div className="content">
                <Grid container type="flex" justify="start" className="card-row">
                    {empfehlungen.map((card, i) => {
                        return <Grid item lg={4} xs={12} className="card-grid-item" key={`card_${i}`}>
                            <Link to={card.link}>
                                <Card className="card">
                                    {card.image && <Image image={card.image} />}
                                    <CardContent>
                                        <h3 className='title-big'>
                                            {card.title}
                                        </h3>
                                        {card.description}
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    })}
                </Grid>
            </div>
        </div>
    )
}

