/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import "./seatrekking.css";

import {Grid} from "@material-ui/core";
import SEO from "../components/SEO";
import Banner from "../components/Banner";
import MultiImageSlider from "../components/MultiImageSlider";
import Contact from "../components/Contact";
import Empfehlungen from "../components/empfehlungen";

export default class Seatrekking extends React.Component {
    render() {
        return <Layout id="seatrekking">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />

            {
                this.props.data.wordpressPage.acf.banner &&
                <Banner
                    title={this.props.data.wordpressPage.acf.banner.title}
                    description={this.props.data.wordpressPage.acf.banner.description}
                    image={this.props.data.wordpressPage.acf.banner.image}
                />
            }

            <Grid container type="flex" justify="center" className='content-wrapper'>
                <Grid item xl={6} lg={8} md={12} xs={12} className='content-centered'>
                    <h2 className='title-big'>{this.props.data.wordpressPage.acf.seatrekking.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.wordpressPage.acf.seatrekking.description }}></div>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <div className='apnoe-intro-image'>
                        {this.props.data.wordpressPage.acf.seatrekking && this.props.data.wordpressPage.acf.seatrekking.image_2 &&
                        <Image image={this.props.data.wordpressPage.acf.seatrekking.image_2}/>}
                    </div>
                </Grid>
            </Grid>

            <Contact title={this.props.data.wordpressPage.acf.contact.title}
               image={this.props.data.wordpressPage.acf.contact.image}
               description={this.props.data.wordpressPage.acf.contact.description} />

            <MultiImageSlider nodes={this.props.data.wordpressPage.acf.impressions}/>

            <Empfehlungen empfehlungen={this.props.data.wordpressPage.acf.empfehlungen_page} />
        </Layout>
    }
}

export const pageQuery = graphql`
    query {
        wordpressPage(slug: {eq: "seatrekking"}) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                contact {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                seatrekking {
                    image_2 {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                    title
                    description
                }
                impressions {
                   alt_text
title
description
localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                empfehlungen_page {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                    link
                }
                banner {
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                    title
                    description
                }
            }
        }
    }
`;


